import { render } from "./MainDetails.vue?vue&type=template&id=b28afb42&scoped=true"
import script from "./MainDetails.vue?vue&type=script&lang=js"
export * from "./MainDetails.vue?vue&type=script&lang=js"

import "./MainDetails.vue?vue&type=style&index=0&id=b28afb42&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-b28afb42"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QRating from 'quasar/src/components/rating/QRating.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QImg,QRating,QBtn,QSeparator,QCardActions});
