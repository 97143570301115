<template>
  <q-card class="q-pa-md">
    <p>KEYWORDS</p>
    <div class="row justify-start">
      <q-card
          class="col q-mx-md bg-deep-purple-6 text-center text-h6 text-no-wrap text-white keyword my-font"
          v-for="(keyword, index) in localKeywords"
          :key="index"
      >{{ keyword }}
      </q-card>
    </div>
    <!--    <q-btn-->
    <!--        class="col q-mx-md text-center text-no-wrap keyword"-->
    <!--        :class="keyword.selectedClass"-->
    <!--        flat-->
    <!--        v-for="(keyword, index) in keywords"-->
    <!--        :key="index"-->
    <!--        :color="keyword.color"-->
    <!--        :label="keyword.label"-->
    <!--        @click="showKeywordOnly(keyword, index)"-->
    <!--        disable-->
    <!--    />-->

    <!--    <q-btn-->
    <!--        class="col bg-deep-orange-6 q-ml-md text-left text-no-wrap keyword new-keyword"-->
    <!--        color="white"-->
    <!--        icon-right="add_circle"-->
    <!--        label="NEW KEYWORD"-->
    <!--        glossy-->
    <!--        flat-->
    <!--    />-->
  </q-card>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Keywords",
  data() {
    return {
      keywordSetting: [
        {
          label: 'keyword1',
          color: 'deep-purple-6',
          selectedClass: 'bg-white',
          onOff: 'false'
        },
        {
          label: 'keyword2',
          color: 'deep-purple-6',
          selectedClass: 'bg-white',
          onOff: 'false'
        },
        {
          label: 'keyword3',
          color: 'deep-purple-6',
          selectedClass: 'bg-white',
          onOff: 'false'
        },
        {
          label: 'keyword4',
          color: 'deep-purple-6',
          selectedClass: 'bg-white',
          onOff: 'false'
        },
        {
          label: 'keyword5',
          color: 'deep-purple-6',
          selectedClass: 'bg-white',
          onOff: 'false'
        },
      ],
      localKeywords: []
    }
  },
  methods: {
    // showKeywordOnly(keyword, key) {
    //   this.setActive(keyword, key)
    // },
    // setActive(keyword, index) {
    //   if (keyword.onOff) {
    //     this.keywords[index].selectedClass = 'bg-deep-purple-6'
    //     this.keywords[index].color = 'white'
    //     this.keywords[index].onOff = false
    //   } else {
    //     this.keywords[index].selectedClass = 'bg-white'
    //     this.keywords[index].color = 'deep-purple-6'
    //     this.keywords[index].onOff = true
    //   }
    // }
  },
  created() {
    this.localKeywords = JSON.parse(localStorage.getItem('lastKeywords'))
  }
}
</script>

<style lang="sass" scoped>

.keyword
  width: auto
  height: 2.5rem
  border: 0.125rem solid #D1C4E9
  border-radius: 10px
  max-width: 20vw

.new-keyword
  border: 0.125rem solid #FFF

</style>