<template>
  <!--  TODO: split this component to title & actions-->
  <div class="testTitle my-font">
    <div style="margin-left:25px; text-align:left; font-size:18px; padding:5px; ">
      {{ product.title }}
    </div>
  </div>

  <div class="row justify-between items-center my-font text-bold no-wrap">

    <div class="q-mx-xs">
      <q-avatar square size="35px" class="" style="margin-left:25px">
        <img :src="flag.image" :alt="flag.name">
      </q-avatar>
      <q-btn v-if="!product.demo" flat dense icon="open_in_new" color="primary" type="a" :href="url" target="_blank"/>
      <q-btn v-if="product.demo" disable flat dense icon="open_in_new" color="primary"/>
    </div>

    <div v-if="!product.vanish && !product.tracked && !product.demo">
      <q-btn flat class="q-mr-xs q-mb-xs" @click="oneAsinTrackOrNot(product.asin, 'tracked')" label="Track" text-color="teal" icon="radio_button_unchecked"/>
      <q-btn flat class="q-mr-md q-mb-xs" @click="oneAsinTrackOrNot(product.asin, 'ignored')" label="Ignore" text-color="red" icon="radio_button_unchecked"/>
    </div>

    <div v-if="!product.vanish && product.tracked && !product.demo">
      <q-btn flat disable class="q-mr-md q-mb-xs" label="Tracked" text-color="teal" icon="radio_button_checked" />
    </div>

    <div v-if="product.vanish && !product.tracked && !product.demo">
      <q-btn flat disable class="q-mr-md q-mb-xs" label="Ignore" text-color="red" icon="radio_button_checked" />
    </div>

    <div v-if="product.demo">
      <q-btn flat disable class="q-mr-xs q-mb-xs" label="Track" text-color="teal" icon="radio_button_checked"/>
      <q-btn flat disable class="q-mr-md q-mb-xs" label="Ignore" text-color="red" icon="radio_button_unchecked"/>
    </div>

  </div>

</template>

<script>
import serverApi from '../../../../../middleware/serverApi';
import {mapState} from 'vuex';

export default {
  computed: {
    ...mapState('marketTracker', ['marketData', 'marketId']),
    url: function () {
      return 'https://www.amazon.com/dp/' + this.product.asin + '?th=1&psc=1'
    },
    // icon(){
      // return asin ? 'radio_button_checked': 'radio_button_unchecked'
    // }
  },
  name: "ProductTitle",
  props: ['product'],
  data() {
    return {
      trackOrIgnore: '',
      flag: {
        name: 'usa',
        image: 'https://upload.wikimedia.org/wikipedia/commons/f/fd/Flag_of_the_United_States_%281846%E2%80%931847%29.svg'
      },
    }
  },

  methods: {
    oneAsinTrackOrNot(key, col) {
      const mid = localStorage.getItem('lastMid')
      serverApi.changeCol(mid, [{asin: key, tracked: false}], col, () => {
      })
      if (col === 'ignored') {
        this.product.ignored = true;
        this.product.vanish = true;
      } else {
        this.product.tracked = true
      }
    },
  },


}
</script>

<style lang="sass" scoped>

</style>
