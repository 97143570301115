<template>
  <q-card flat>
    <q-card-section class="col-6">
      <div><b>Price: </b>${{ product.price }}</div>
      <div><b>BSR: </b>{{ addComma(product.bsr) }}</div>
      <div><b>Number Of Sellers: </b> {{ product.seller }}</div>
      <div style="text-transform: capitalize"><b>Fulfillment: </b> {{ product.fulfillment }}</div>
    </q-card-section>

    <q-separator/>

    <q-card-section class="text-center row" horizontal style="text-align: left">

      <q-card-section class="col-6">
        <div class="text-subtitle2 q-ma-sm"><b>Estimated Sales: </b> {{ addComma(product.sales) }}</div>
        <q-separator/>
        <div class="text-subtitle2 q-ma-sm"><b>Sales Share: </b>{{ addComma(product.salesShare) }}%</div>
      </q-card-section>

      <q-separator vertical/>

      <q-card-section>
        <div class="text-subtitle2 q-ma-sm"><b>Estimated Revenue: </b>${{ addComma(product.revenue) }}</div>
        <q-separator/>
        <div class="text-subtitle2 q-ma-sm"><b>Market Share: </b>{{ addComma(product.marketShare) }}%</div>
      </q-card-section>

    </q-card-section>
  </q-card>
</template>

<script>
export default {
  name: "SalesDetails",
  props: ['product'],
  methods: {
    addComma(number) {
      if (number != null) {
        return Number(number).toLocaleString()
      }
    }
  },

}
</script>

<style lang="sass" scoped>


</style>
