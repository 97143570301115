import { render } from "./Keywords.vue?vue&type=template&id=a9442c68&scoped=true"
import script from "./Keywords.vue?vue&type=script&lang=js"
export * from "./Keywords.vue?vue&type=script&lang=js"

import "./Keywords.vue?vue&type=style&index=0&id=a9442c68&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-a9442c68"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QBtn});
