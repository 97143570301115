import { render } from "./ProductTitle.vue?vue&type=template&id=02023050&scoped=true"
import script from "./ProductTitle.vue?vue&type=script&lang=js"
export * from "./ProductTitle.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-02023050"

export default script
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QAvatar,QBtn});
