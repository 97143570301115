<script>
import {defineComponent} from "vue";
import {Doughnut} from "vue3-chart-v2";

export default defineComponent({
  name: "doughnutConfig",
  extends: Doughnut,
  watch: {
    render () {
      this.renderChart({
        labels: this.labels,
        datasets: this.datasets,
      });
    }
  },
  mounted() {
    this.renderChart({
      labels: this.labels,
      datasets: this.datasets,
    });
  },
  props: ["datasets", "labels","render"],
});
</script>