import { render } from "./SalesDetails.vue?vue&type=template&id=27b40c33&scoped=true"
import script from "./SalesDetails.vue?vue&type=script&lang=js"
export * from "./SalesDetails.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-27b40c33"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QSeparator});
