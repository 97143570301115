<template>
  <q-card flat>
    <q-card-section horizontal>
      <q-img
          class="q-ma-xs col-6"
          :src="product.img"
          :ratio="16/9"
          fit="scale-down"
      />
      <q-card-section class="q-pt-xs">
        <div class="row no-wrap items-center">
          <q-rating
              v-model="product.averageRatings"
              :max="5"
              readonly
              size="25px"
              icon-half="star_half"
          />
          <span class="text-caption text-grey q-ml-sm">{{ product.averageRatings }}</span>
        </div>
        <div class="q-mt-sm q-mb-xs"><b>Brand: </b>{{ product.brand }}</div>
        <div class="q-mt-sm q-mb-xs"><b>Category: </b>{{ product.category }}</div>
        <!--          TODO: add copy button for asin-->
        <div class="q-mt-sm q-mb-xs"><b>Asin:</b> {{ product.asin }}</div>
        <div class="q-mt-sm q-mb-xs"><b>Reviews: </b>{{ Number(product.totalReviews).toLocaleString() }}</div>
        <div class="q-mt-sm q-mb-xs q-pt-xs row">
          <b class="q-mr-xs">Variations: </b>
          <div v-if="showVariation">{{ variations }}</div>
          <q-btn
              dense
              class="my-font text-bold text-capitalize"
              size="11px"
              label="reveal"
              outline
              v-if="!showVariation"
              text-color="primary"
              @click="getVariationsCount(product.asin)"
          />
        </div>
      </q-card-section>
    </q-card-section>
<!--    <q-separator/>-->
<!--    <q-card-actions>-->
<!--      <q-btn flat round icon="event"/>-->
<!--      <q-btn flat>-->
<!--        5:30PM-->
<!--      </q-btn>-->
<!--    </q-card-actions>-->
  </q-card>
</template>

<script>
import serverApi from "../../../../../middleware/serverApi";

export default {
  name: "MainDetails",
  props: ['product'],
  data() {
    return {
      showVariation: false,
      variations: null
    }
  },
  methods: {
    async getVariationsCount(asin) {
      this.variations = await serverApi.getNumberOfVariations(asin)
      this.showVariation = true
    },
  },
}
</script>

<style lang="sass" scoped>

.productImg
  height: 13.75rem
  max-height: 13.75rem

  width: 18.125rem
  max-width: 18.125rem


</style>

